export const achievementData = {
    bio: "The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn. - Alvin Toffler",
    achievements: [
        {
            id: 1,
            title: 'Clash of Coders',
            details: 'A coding Competition',
            date: 'Feb 28, 2020',
            field: 'Coding',
            image: ''
        },
        {
            id: 2,
            title: 'Introduction to Python Workshop',
            details: 'Attend a workshop in python language',
            date: 'May 25, 2020',
            field: 'Python',
            image: ''
        },
        {
            id: 3,
            title: 'Flutter & Dart - The Complete Guide [2022 Edition]',
            details: 'A Complete Guide to the Flutter SDK & Flutter Framework for building native iOS and Android apps by Maximilian Schwarzmuller',
            date: 'August 20, 2021',
            field: 'Mobile Application Development',
            image: ''
        },
        {
            id: 3,
            title: 'React Native - The Practical Guide [2022]',
            details: 'Use React Native and your React knowledge to build native iOS and Android Apps - incl. Push Notifications, Hooks, Redux by Maximilian Schwarzmuller',
            date: 'Sep 28, 2021',
            field: 'Mobile Application Development',
            image: ''
        },
        {
            id: 4,
            title: 'Nest JS - The Complete Developer Guide',
            details: 'Build full featured backend APIs incredibly quickly with Nest, TypeORM, and Typescript. Includes testing and deployment! by Stephen Grider',
            date: 'Oct 18, 2022',
            field: 'Backend Development',
            image: ''
        }
    ]
}

