export const socialsData = {
    github: 'https://github.com/iamsaadMehmood',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/iamsaadMehmood/',
    instagram: '',
    codepen: '',
    twitter: 'https://twitter.com/iamsaadMehmood',
    reddit: 'https://www.reddit.com/user/real_warriorex',
    blogger: '',
    medium: '',
    stackOverflow: 'https://stackoverflow.com/users/16981415/saad-mehmood',
    gitlab: '',
    youtube: ''
}