export const skillsData = [
    'Javascript',
    'Typescript',
    'React',
    'React Native',
    'Redux',
    'MobX',
    'Nest JS',
    'Git',
    'Firebase',
    'Flutter',
    'Dart',
    'Python',
    'FastApi',

];


