export const educationData = [

    {
        id: 1,
        institution: 'University of Central Punjab',
        course: 'Bachelor of Science in Computer Science',
        startYear: '2017',
        endYear: '2021'
    },
    {
        id: 2,
        institution: 'Punjab Group of College',
        course: 'Intermediate in Computer Science',
        startYear: '2015',
        endYear: '2017'
    },
    {
        id: 3,
        institution: 'Pakistan Public School',
        course: 'Matriculation',
        startYear: '2013',
        endYear: '2015'
    },
]